export function useScrollPercentage(element: HTMLElement | null = null) {
    if (!element) {
        element = document.body
    }
    const parentNode = <Element>element.parentNode
    if (!parentNode) {
        return 0
    }
    return (element.scrollTop || parentNode.scrollTop) / (parentNode.scrollHeight - parentNode.clientHeight) * 100
}

export async function copyToClipboard(text: string) {
    try {
        if (!navigator.clipboard) {
            return
        }
        await navigator.clipboard.writeText(text)
    } catch (err) {
        console.log(err)
    }
}
