<template>
	<div v-if="value || showNull" data-testid="price-display">
		<div class="whitespace-nowrap">
			<span v-if="Math.sign(value) === -1">-</span>
			{{ formattedPositivePrice }}
		</div>
		<div v-if="slots.default">
			<slot></slot>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {computed, useSlots} from "vue"

const props = withDefaults(defineProps<{
	value?: number
	currency?: string
	locale?: string
	showNull?: boolean
}>(), {
	currency: "CHF",
	locale: "de-CH",
	showNull: false,
})

const slots = useSlots()

const toCurrency = (value: number): string => {
	return new Intl.NumberFormat(props.locale, {
		style: "currency",
		currency: props.currency,
	}).format(value)
}

const formattedPositivePrice = computed((): string | null => {
	if (!props.value && !props.showNull) {
		return null
	}
	if (!props.value) {
		return toCurrency(0)
	} else {
		const positiveValue = Math.abs(props.value)
		return toCurrency(positiveValue)
	}
})
</script>
