import axios, {AxiosResponse} from 'axios'
import {DeleteSourceUrlForm, IgnoreSourceUrlForm,} from "@/js/interfaces/source_relation"
import {ReviewFormInterface} from "@/js/interfaces/review"
import {
    AssetListResponse,
    EventResponse,
    EventTitleListResponse, OrderStatsResponse,
    SourceEventCreatedResponse,
    SourceEventResponse,
    SpeakerListResponse,
    StatsResponse, StudentUpdaterFormInterface,
    TagListResponse,
    TopCategoryListResponse,
    UpcomingEventdatesListResponse
} from "@/js/api/interfaces"
import {StatsRequest} from "@/js/interfaces/stats"
import {AssetCreateFormInterface, AssetInterface, AssetUpdateFormInterface} from "@/js/interfaces/asset"
import {AiRequestInterface} from "@/js/interfaces/ai"

const http = axios.create({
    baseURL: "/",
    headers: {'Content-Type': 'application/json'}
})

http.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

export async function getEventTitles(): Promise<EventTitleListResponse> {
    const response = await http.get("/eventcalendar/event/titles")
    return response.data
}

export async function searchTags(searchText: string): Promise<TagListResponse> {
    const response = await http.get("/tag/list/?q=" + searchText)
    return response.data
}

export async function getEventTags(eventId: number): Promise<TagListResponse> {
    const response = await http.get("/eventcalendar/event/tags/event_id/" + eventId)
    return response.data
}

export async function getSourceEvent(fetchUrl: string): Promise<SourceEventResponse> {
    const response: AxiosResponse = await http.get(fetchUrl)
    return response.data
}

export async function getNewsletterStatsForTemplate(templateId: number, fetchUrl: string): Promise<StatsResponse> {
    const statsUrl = new URL(fetchUrl)
    statsUrl.searchParams.set("template_id", templateId.toString())
    const response: AxiosResponse = await http.get(statsUrl.toString())
    return response.data
}

export async function triggerSourceEventUpdate(triggerUrl: string): Promise<string> {
    const response = await http.get(triggerUrl)
    return response.data
}

export async function addEventFromSource(eventForm, submitUrl: string): Promise<SourceEventCreatedResponse> {
    const response = await http.post(submitUrl, eventForm)
    return response.data
}

export async function getEventById(eventId: number): Promise<EventResponse> {
    const response: any = await http.get("/eventcalendar/event/get?id=" + eventId)
    return response.data
}

export async function getTopCategories(): Promise<TopCategoryListResponse> {
    const response = await http.get("/eventcalendar/ajax/topCategoryListJson")
    return response.data
}

export async function getSpeakers(): Promise<SpeakerListResponse> {
    const response = await http.get("/api/rest/eventapi/speaker?limit=9999")
    return response.data
}

export async function getUpcomingCategoryEventdates(categoryId: number | undefined, startDateOffset = 10): Promise<UpcomingEventdatesListResponse> {
    const response: AxiosResponse = await http.get("/eventcalendar/eventdate/upcoming?category=" + categoryId + "&offset=" + startDateOffset)
    return response.data
}

export async function ignoreSourceUrl(ignoreForm: IgnoreSourceUrlForm, ignoreUrl: string): Promise<AxiosResponse<IgnoreSourceUrlForm>> {
    const response = await http.post(ignoreUrl, ignoreForm)
    return response.data
}

export async function deleteSourceUrl(deleteForm: DeleteSourceUrlForm, deleteUrl: string): Promise<AxiosResponse<DeleteSourceUrlForm>> {
    const response = await http.post(deleteUrl, deleteForm)
    return response.data
}

export async function addReview(reviewForm: ReviewFormInterface): Promise<string> {
    const response = await http.post('/review/ajax/add', reviewForm)
    return response.data
}

export async function getOrderStats(statsRequest: StatsRequest, fetchUrl: string): Promise<OrderStatsResponse> {
    const response = await http.get(fetchUrl, {params: statsRequest})
    return response.data
}


export async function changeOrderEmail(orderId: number, email: string, submitUrl: string, formKey: string, resendOrderEmail?: boolean, updateCustomerEmail?: boolean): Promise<AxiosResponse> {
    const response = await http.post(submitUrl, {
        order_id: orderId,
        email: email,
        resend_order_email: resendOrderEmail || false,
        update_customer_email: updateCustomerEmail || false,
        form_key: formKey
    })
    return response.data
}

export async function changeStudent(updateForm: StudentUpdaterFormInterface, submitUrl: string): Promise<AxiosResponse> {
    const response = await http.post(submitUrl, updateForm)
    return response.data
}

export async function getAssets(viewUrl: string): Promise<AssetListResponse> {
    const response = await http.get(viewUrl)
    return response.data
}

export async function createAsset(createForm: AssetCreateFormInterface, submitUrl: string): Promise<AssetInterface> {
    const formData = new FormData()
    Object.keys(createForm).forEach(key => {
        if (createForm[key] !== null && createForm[key] !== undefined) {
            formData.append(key, createForm[key])
        }
    })
    const response = await axios.post(submitUrl, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    return response.data
}

export async function deleteAsset(assetId: number | string, submitUrl: string): Promise<AxiosResponse> {
    const response = await http.get(submitUrl + "?id=" + assetId)
    return response.data
}

export async function updateAsset(updateForm: AssetUpdateFormInterface, submitUrl: string): Promise<AssetInterface> {
    const response = await http.post(submitUrl, updateForm)
    return response.data
}

export async function callAi(aiForm: AiRequestInterface): Promise<string> {
    const response = await http.post(aiForm.url, {
        prompt: aiForm.prompt,
        form_key: aiForm.form_key,
        language_model: aiForm.language_model,
        role: aiForm.role,
        temperature: aiForm.temperature
    })
    return response.data
}

