import axios, {AxiosResponse} from 'axios'
import {TicketInterface} from "@/js/interfaces/ticket"
import {CheckoutFormInterface} from "@/js/interfaces/checkout"
import {EventdateInterface} from "@/js/interfaces/eventdate"
import {CartEventInterface} from "@/js/interfaces/event"
import {CatalogSearchConfigInterface} from "@/js/interfaces/catalogsearch"
import {CaptchaConfigInterface} from "@/js/interfaces/captcha"
import {NewsletterFormInterface} from "@/js/interfaces/newsletter"
import {CountryValueInterface} from "@/js/interfaces/allowed_countries"

const http = axios.create({
    baseURL: "/",
    headers: {'Content-Type': 'application/json'}
})

http.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

export async function getCart(): Promise<AxiosResponse> {
    const response = await http.get("vue-checkout/cart/get")
    return response.data
}

export async function getAllowedCountries(): Promise<CountryValueInterface[]> {
    const response = await http.get("vue-checkout/cart/countries")
    return response.data
}

export async function getCustomerAccount(): Promise<AxiosResponse> {
    const response = await http.get("vue-checkout/account/get")
    return response.data
}

export async function logoutCustomer(): Promise<AxiosResponse> {
    const response = await http.get("vue-checkout/account/logout")
    return response.data
}

export async function bookTicket(ticket: TicketInterface, submitUrl: string): Promise<TicketInterface[]> {
    const response = await http.post(submitUrl, ticket)
    return response.data
}

export async function deleteTicket(id: number, formKey: string): Promise<AxiosResponse> {
    const response = await http.delete("/vue-checkout/ticket/delete/id/" +
        id +
        "/form_key/" + formKey)
    return response.data
}

export async function submitOrder(checkoutForm: CheckoutFormInterface, submitUrl: string): Promise<AxiosResponse> {
    const response = await http.post(submitUrl, checkoutForm)
    return response.data
}

export async function joinAsPartner(joinForm, submitUrl: string): Promise<AxiosResponse> {
    const response = await http.post(submitUrl, joinForm)
    return response.data
}

export async function subscribeToNotification(subscriptionForm, submitUrl: string) {
    const response = await http.get(submitUrl, {
        params: subscriptionForm
    })
    return response.data
}

export async function subscribeToNewsletter(subscriptionForm: NewsletterFormInterface, submitUrl: string) {
    const response = await http.post(submitUrl, subscriptionForm)
    return response.data
}

export async function askQuestion(questionForm, submitUrl: string) {
    const response = await http.post(submitUrl, questionForm)
    return response.data
}

export async function getProposedModuleDates(eventId: number, earliestStartDate: string): Promise<EventdateInterface[]> {
    const response = await http.get("/course/eventdates/proposed", {
        params: {
            event_id: eventId,
            earliest_start: earliestStartDate,
        },
    })
    return response.data
}

export async function getUpcomingEventdatesForCategory(categoryId: number): Promise<any> {
    const response = await http.get("/eventcalendar/ajax/upcomingCategoryEventdatesListJson", {
        params: {
            id: categoryId,
        },
    })
    return response.data
}

export async function getNewEventsForCategory(categoryId: number | null): Promise<CartEventInterface[]> {
    const response = await http.get("/eventcalendar/ajax/newCategoryEventdatesListJson", {
        params: {
            id: categoryId,
        },
    })
    return response.data
}

export async function getSearchResults(query: string, config: CatalogSearchConfigInterface): Promise<any> {
    const response = await http.get(config.urls.suggest, {
        params: {
            q: query,
        },
    })
    return response.data

}

export async function getCaptchaConfig(): Promise<CaptchaConfigInterface> {
    const response = await http.get("/ovc_captcha/ajax/config")
    return response.data
}

