import {createStore, Store, useStore as baseUseStore} from "vuex"
import VuexPersistence from "vuex-persist"
import {bookTicket, deleteTicket, getCart, getCustomerAccount, logoutCustomer} from '../api/sp'
import {
    CartConfigInterface, CartStoreInterface, CustomerAddressesInterface, CustomerStoreInterface, StoreStateInterface
} from "@/js/interfaces/vuex"

import {InjectionKey} from "vue"
import {CartTotalInterface} from "@/js/interfaces/cart"
import {BookedTicketInterface} from "@/js/interfaces/ticket"
import {CustomerAddressInterface} from "@/js/interfaces/address"

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: "vuex",
})

export const key: InjectionKey<Store<StoreStateInterface>> = Symbol()

export function useStore() {
    return baseUseStore(key)
}

export const store = createStore<StoreStateInterface>({
    plugins: [vuexLocal.plugin],
    state: {
        cart: {
            config: {} as CartConfigInterface,
            tickets: [] as BookedTicketInterface[],
            totals: [] as CartTotalInterface[]
        } as CartStoreInterface,
        customer: {} as CustomerStoreInterface,
        billing_address: {
            company: null,
            firstname: null,
            lastname: null,
            street: [],
            postcode: null,
            city: null,
            country_id: "CH",
            telephone: null,
            email: null,
        } as CustomerAddressInterface,
    },
    mutations: {
        SET_TICKETS(state, payload) {
            state.cart.tickets = payload
        },
        SET_CART(state, payload) {
            state.cart = payload
        },
        SET_CUSTOMER(state, payload) {
            state.customer = payload
        },
        SET_BILLING_ADDRESS(state, payload) {
            state.billing_address = payload
        }
    },
    actions: {
        async deleteTicket({state, dispatch}, id: number) {
            await deleteTicket(id, state.cart.config.form_key)
            await dispatch("reloadCart")
        },
        async bookTicket({dispatch}, {ticket, submitUrl}) {
            await bookTicket(ticket, submitUrl)
            dispatch("reloadCart")
        },
        async reloadCart({commit}) {
            const data = await getCart()
            commit("SET_CART", data)
        },
        async reloadCustomer({commit}) {
            const data = await getCustomerAccount()
            commit("SET_CUSTOMER", data)
        },
        async logout({commit}) {
            try {
                const data = await logoutCustomer()
                commit("SET_CUSTOMER", data)
                return true
            } catch (e) {
                return false
            }
        },
        setBillingAddress({commit}, address: CustomerAddressInterface) {
            commit("SET_BILLING_ADDRESS", address)
        }
    },
    getters: {
        cart(state): CartStoreInterface {
            return state.cart
        },
        cartConfig(state): CartConfigInterface | null {
            if (Object.prototype.hasOwnProperty.call(state.cart, "config")) {
                return state.cart.config
            }
            return null
        },
        cartEmpty(state): boolean {
            return state.cart.tickets.length === 0
        },
        cartItemsCount(state, getters): number {
            if (getters["cartEmpty"]) return 0
            return state.cart.tickets.length
        },
        customer(state): CustomerStoreInterface {
            return state.customer
        },
        customerAddresses(state): CustomerAddressesInterface | undefined {
            if (!Object.prototype.hasOwnProperty.call(state.customer, "addresses"))
                return undefined
            return state.customer.addresses
        },
        customerDefaultAddress(state) {
            if (!Object.prototype.hasOwnProperty.call(state.customer, "addresses"))
                return null
            if (
                !Object.prototype.hasOwnProperty.call(
                    state.customer.addresses,
                    "default"
                )
            ) {
                return null
            }
            return state.customer.addresses?.default
        },
        tickets(state): BookedTicketInterface[] {
            return state.cart.tickets
        },
        loggedIn(state) {
            return state.customer.loggedIn
        },
        billingAddress(state): CustomerAddressInterface {
            return state.billing_address
        }
    },
})
export default store
